enum ACTIONS {
  REGISTER_HOST,
  DEREGISTER_HOST,
  ADD_UPDATE_PORTAL,
  REMOVE_PORTAL,
}

const INITIAL_STATE = {};

export { ACTIONS, INITIAL_STATE };
